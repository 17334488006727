import { ZoomIcon } from 'components/icons/zoom'
import { preventDefault } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { ZoomUser } from 'shared_server_client/types/zoom'
import { injectReducer } from 'store/index'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'
import { CommunitySystem, getCommunityIntegrations, IntegrationSystems } from 'shared/data-layer/integration'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  options: string,
  type: string,
}

type AddSourceProps = GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  activeCommunity: any,
  existingSources: any[],
  existingZoomSource: any,
  hasZoomIntegration: boolean,
  hasZoomSource: boolean,
  errorMessage: string,
  isComponentLoading: boolean,
  name: string,
  selectedUser: ZoomUser, // will set this up when we find the input organization
  showUser: boolean,
  zoomIntegration: CommunitySystem,
  zoomSource: any,
}

class ContentPoolAddZoomSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource')

    this.state = {
      activeCommunity: null,
      existingSources: [],
      existingZoomSource: null,
      hasZoomSource: false,
      hasZoomIntegration: false,
      errorMessage: '',
      isComponentLoading: true,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      name: '',
      selectedUser: null,
      showUser: false,
      zoomIntegration: null,
      zoomSource: null,

    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({ activeCommunity })
      Promise.all([
        new Dataset().loadCommunityDataset('communitySources', activeCommunity.communityId),
        getCommunityIntegrations(activeCommunity, person),
      ])
        .then(([sourcesResponse, integrationsResponse]: [any, CommunitySystem[]]) => {
          const existingSources = sourcesResponse[0] || []
          const zoomSource = existingSources.filter((s) => {
            return s.source_type === SourceTypes.zoom && s.is_active
          })
          const zoomIntegration = integrationsResponse.filter((s: CommunitySystem) => {
            return s.name === IntegrationSystems.Zoom && s.is_active
          })
          this.setState({
            existingSources,
            hasZoomIntegration: zoomIntegration.length > 0,
            hasZoomSource: zoomSource.length > 0,
            existingZoomSource: zoomSource.length > 0 ? zoomSource[0] : null,
            isLoading: false,
            zoomIntegration: zoomIntegration[0],
            zoomSource: zoomSource[0],
          }, () => {
            if ((!this.state.hasZoomSource) || this.context.store.getState().app.params.connected === 'true') {
              // get user information
              this.getZoomUser()
              .then((zoomUser: ZoomUser) => {
                this.setState({
                  isComponentLoading: false,
                  isLoading: false,
                  name: this.state.existingZoomSource ? this.state.existingZoomSource.name : '',
                  selectedUser: zoomUser,
                  showUser: true,
                })
              })
              .catch((ex) => {
                // eslint-disable-next-line no-console
                console.error(ex)
                this.setState({
                  isComponentLoading: false,
                  isLoading: false,
                })
              })
            } else {
              this.setState({
                isComponentLoading: false,
                isLoading: false,
              })
            }
          })
        })
        .catch((ex) => {
          this.setState({ isComponentLoading: false })
          // hanlde error in UI
          // eslint-disable-next-line no-console
          console.log(ex)
        })
    })
  }

  public render = () => {
    return this.state.isComponentLoading ? <Loading.Loading size={Loading.SIZES.LARGE} /> :
      this.state.hasZoomSource && !this.state.showUser
        ? <div className={`${this.context.store.getState().config.isMobile ? 'add-zoom-wrapper-mobile' : 'add-zoom-wrapper'}`}>
            <div className="source-exists" >You already have {SourceTypes.zoom} as a source!</div>
          </div>
        :
        <div className={`${this.context.store.getState().config.isMobile ? 'add-zoom-wrapper-mobile' : 'add-zoom-wrapper'}`}>
          {!isEmpty(this.state.selectedUser) &&
            <div className={`${this.context.store.getState().config.isMobile ? 'add-zoom-wrapper-mobile' : 'add-zoom-wrapper'}`}>
              <h2 className="sources-heading">Sources</h2>
              <div className="section-header-text">Add {SourceTypes.zoom} Source</div>
              <p className="section-detail-text">We will pull webinars from your account.</p>
              <div className="zoom confirm sources">
                <div className="image">
                  <ZoomIcon  />
                </div>
                <div className="words">
                  <h2>{SourceTypes.zoom}</h2>
                  <p>
                    <strong className="feed-result-text">
                      We connected to your {this.state.selectedUser.email} account.</strong>
                  </p>
                  <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
                  {this.state.name.trim().length > 0 && !this.isValidZoomSourceName() &&
                    <div className="invalid-source-name-container">You already have a source with this name</div>}
                  <div className="input-area">
                    <form onSubmit={preventDefault()}>
                      <input autoFocus className="field-textbox"
                        value={this.state.name}
                        onChange={(e: any) => this.setState({ name: e.target.value })}
                      />
                      <Button
                        onClick={() => this.addSource({
                          community_id: this.state.activeCommunity.communityId,
                          identifier: `zoom-${this.state.activeCommunity.communityId}`,
                          name: this.state.name.trim(),
                          options: JSON.stringify({ user_id: this.state.selectedUser.id, image_url: this.state.selectedUser.pic_url || ''}),
                          type: SourceTypes.zoom,
                        })
                        }
                        disabled={this.state.isComponentLoading || !this.isValidZoomSourceName()}>Confirm</Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
  }

  private isValidZoomSourceName = (): boolean => {
    if (this.state.name.trim() === '') {
      return false
    }
    if (this.state.existingZoomSource
      && this.state.existingZoomSource.name.toLowerCase() === this.state.name.toLowerCase()) {
      return true
    }
    return isValidSourceName(this.state.existingSources, this.state.name)
  }

  private getZoomUser(): Promise<any> {
    return AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/integrations/${this.state.activeCommunity.communityId}/source/${this.state.zoomIntegration.community_system_id}`,
      HttpMethod.POST,
      {
        endpoint: '/users/me',
        sourceId: this.state.zoomIntegration.id,
      },
    )
    .then((userResponse: any) => {
      return userResponse
    })
  }


  private addSource = (source: Source) => {
    this.setState({ communityId: this.state.activeCommunity.communityId }, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.state.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'zoom', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddZoomSource = GenericRedux.createConnect(ContentPoolAddZoomSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
